import * as React from "react"
import { render } from "react-dom"
import { PortableText } from "@portabletext/react"
import Slider from "../Slider"
import { getImageUrl } from "../../utils/getImageUrl"
import LazyLoad from "react-lazy-load"
import parse from "html-react-parser"
import { useEffect } from "react"
import { ChevronUpIcon } from "@heroicons/react/24/outline"

const HEADERS = ["H1", "H2", "H3", "H4", "H5", "H6"]

const Content = ({ content, contentData }) => {
  const startHeaderTag = "&lt;START_ACCORDION_HEADER&gt;"
  const endHeaderTag = "&lt;END_ACCORDION_HEADER&gt;"
  const startBodyTag = "&lt;START_ACCORDION_BODY&gt;"
  const endBodyTag = "&lt;END_ACCORDION_BODY&gt;"
  let modifiedContent
  if (contentData) {
    modifiedContent = contentData
      .replaceAll(startHeaderTag, "")
      .replaceAll(endHeaderTag, "")
      .replaceAll(startBodyTag, `<div className="accordionBody" >`)
      .replaceAll(endBodyTag, "</div>")
  }
  useEffect(() => {
    const allAccordionBody = document.querySelectorAll(".accordionBody")
    const images = document.querySelectorAll("img[data-mobile-width]")
    images.forEach(image => {
      const mobileWidth = image.getAttribute("data-mobile-width")
      image.style.width = mobileWidth + "px"
      image.classList.add("imageSize")
    })
    allAccordionBody.forEach(accordionBody => {
      const buttonWithIcon = document.createElement("button")

      const buttonText = document.createElement("span")
      buttonText.textContent = "Läs mer"
      buttonWithIcon.appendChild(buttonText)
      buttonWithIcon.classList.add(
        "flex",
        "item-center",
        "gap-[5px]",
        "accordion",
        "font-bold",
        "pt-3"
      )
      const divWithIcon = document.createElement("div")
      divWithIcon.classList.add("chevronUpIcon")
      render(<ChevronUpIcon height="100%" />, divWithIcon)
      buttonWithIcon.appendChild(divWithIcon)
      accordionBody.insertAdjacentElement("afterend", buttonWithIcon)
      buttonWithIcon.addEventListener("click", event => {
        const currentMaxHeightValue = accordionBody.style.maxHeight
        accordionBody.style.maxHeight =
          currentMaxHeightValue === "100%" ? "0px" : "100%"
        const currentNameButtonValue = buttonText.textContent
        buttonText.textContent =
          currentNameButtonValue === "Läs mindre" ? "Läs mer " : "Läs mindre"

        const currentChevronState = divWithIcon.style.rotate
        divWithIcon.style.rotate =
          currentChevronState === "0deg" ? "180deg" : "0deg"
      })
    })
  }, [])

  return (
    <div className="elva content px-4 lg:px-0 lg:pt-2.5 pb-12 lg:pb-16">
      {contentData && parse(modifiedContent)}
      {content &&
        content.map(item => {
          return (
            <div key={item._key}>
              <h3 className="mb-2 mt-5">
                <strong>{item.heading}</strong>
              </h3>
              <div>
                {item.image && (
                  <LazyLoad>
                    <img
                      className={`w-full h-full ${
                        item.layout === "imageleft"
                          ? "md:float-left md:mx-4 md:mb-4 md:w-1/2"
                          : `${
                              item.layout === "imageright"
                                ? "md:float-right md:mx-4 md:mb-4 md:w-1/2"
                                : ""
                            } `
                      }`}
                      style={{ maxWidth: `${item.imageSize}px` }}
                      src={getImageUrl(item.image?.asset?._ref)}
                      alt={item.heading}
                    />
                  </LazyLoad>
                )}
                <PortableText value={item.content} />

                {item._type === "logos" && (
                  <div className="flex flex-wrap justify-center gap-4 items-center">
                    {item.logos.map(image => (
                      <LazyLoad>
                        <img
                          className={`w-full h-full object-cover`}
                          style={{ maxWidth: `150px`, maxHeight: `150px` }}
                          src={getImageUrl(image?.asset?._ref)}
                        />
                      </LazyLoad>
                    ))}
                  </div>
                )}

                {item._type === "imageGrid" && (
                  <Slider data={item.gridImages} />
                )}
              </div>
              <div className="clear" />
            </div>
          )
        })}
    </div>
  )
}

export default Content
